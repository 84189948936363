import { genMuiThemes } from 'clnt-common';

const ltp    = '#f1ecf7';    // light color with red tint - nav bar bg color
const lts    = '#9c27b0';    // button bg in light mode (darker purple) - secondaryy / also used in the manifest.webmanifest
const ltpnl  = '#ffffff';    // light color with darker red tint for panel - 22 biggest... section bg color
const ltcpnl = '#f1ecff';    // light content panel -   (The same is used in manifest.webmanifest)

const dkp    = '#000000';    // '#121212',
const dks    = '#ce93d8';    // button bg in dark mode (lighter purple) - secondary
const dkpnl  = '#030303';    // compliment to ltpnl
const dkcpnl = '#000000';    // dark content panel

const options = {
  fontHeader :      'Philosopher',
  fontText :        'Roboto',

  primaryLight:     ltp,   //'#f1ecf7', //'#ededed',
  secondaryLight:   dks,   //'#9c27b0',
  panelBgLight:     ltpnl,   //'#ffffff', // '#f3e5f7',
  backpanelBgLight: ltp,   //'#ffffff',
  contentpanelBgLight: ltcpnl,

  primaryDark:      dkp,   //'#000000',
  secondaryDark:    lts,   //'#ce93d8',
  panelBgDark:     dkpnl,   // '#0e0e0e',
  backpanelBgDark:  dkp,   //'#000000',
  contentpanelBgDark:  dkcpnl,

  pagePadding : 1,
  pageGap : 0,

  // For use within pages.ts (rendered via PageContentFormat.jsx)
  // 5 sets of areas.
  areaLight: [{ panel: '', border: '#cccccc' },  // about
              { panel: '', border: '#3845f3' },  // service:matrix
              { panel: '#e8eafb', border: '' },  // service:chat
              { panel: '#af9d9d', border: '#cccffa' },  // service: *
              { panel: '', border: '' }],  
  areaDark:  [{ panel: '', border: '' },  
              { panel: '', border: '' },  
              { panel: '', border: '' },  
              { panel: '', border: '' },  
              { panel: '', border: '' }]  
};

export { themes };
let themes = genMuiThemes(options);


